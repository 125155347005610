import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      address: [],
      select: {},
      show11: 0
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    to_rec: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'rec',
        query: {
          id: id1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    },
    to_apply: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'apply',
        query: {
          id: id1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    onload: function (name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.goods = [];
        this.page = 1;
        this.finished = false;
        this.is_has = 1;
      }
      api.all('/api/user/user_reward', {
        page: this.page
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.loading = false;
          this.is_jia = false;
          let oldArr = this.address;
          let newArr = data.data.dizhi;
          this.address = oldArr.concat(newArr);
          //this.banner = data.data.banner;

          if (data.data.count <= this.page * 20) {
            this.finished = true;
          }
          this.page++;
        } else {
          console.log(data);
        }
      });
    },
    onOversize(file) {
      console.log(file);
      showToast(this.lang.t6);
    },
    get_imdex_data: function () {
      api.all('/api/user/user_reward', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
        } else {
          console.log(data);
        }
      });
    },
    up: function () {
      let data = this.select;
      data.type = this.show11;
      api.all('/api/user/user_reward', data, (err, data) => {
        showToast(data.msg);
      });
    },
    de: function (id) {
      api.all('/api/user/de_reward', {
        id: id
      }, (err, data) => {
        showToast(data.msg);
        this.get_imdex_data();
      });
    },
    show_d(type) {
      api.all('/api/user/user_reward', {
        gz_id: this.guize.data.id
      }, (err, data) => {
        showToast(data.msg);
        this.get_imdex_data();
      });
    },
    detail: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
    },
    chat: function (type = 0) {
      api.chat(type);
    }
  }
};